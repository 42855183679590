/* You can add global styles to this file, and also import other style files */
/* @import '~materialize-css/dist/css/materialize.min.css'; */
.mat-table__wrapper {
  width: 100%;
  overflow-x: auto;
}
.mat-table__wrapper mat-cell, .mat-table__wrapper mat-header-cell {
  min-height: 100%;
}

.mat-table__wrapper .mat-table {
  min-width: 1000px;
  width: 100%;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
}

.mat-table__wrapper .mat-table .email-cell {
  word-wrap: normal;
  width: 180px;
  flex: auto;
}

.m-mat-dialog-container__wrapper .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
}
.m-mat-dialog-container__wrapper .mat-dialog-container .m-portlet {
  margin-bottom: 0;
}
.m-mat-dialog-container__wrapper .mat-dialog-container .alert {
  border-radius: 0 !important;
  width: 1032px;
}

.mat-table__wrapper--sub .mat-table {
  min-width: 400px;
}
.mat-table__wrapper--sub .mat-table .mat-cell:last-child {
  flex: 0 0 100px;
}
.mat-table__wrapper--sub .mat-table .mat-header-cell:last-child {
  flex: 0 0 100px;
}

.ml-auto {
  margin-left: auto;
}

.example-full-width {
  width: 100%;
}

mat-radio-button {
  padding-right: 16px;
}

.m-form--alert {
  margin-top: 30px;
}

.mat-column-checkbox {
  flex: 0 0 68px;
}

.mat-column-id {
  flex: 0 0 68px;
}

.mat-column-main {
  flex: 0 0 568px;
}

.mat-column-actions {
  flex: 0 0 160px;
}

.mat-table__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-vinCode {
  flex: 0 0 170px;
}

.m-portlet-mx-0 {
  margin: -1.1rem -2.2rem 0;
  overflow-x: hidden;
}

.mat-table__wrapper .mat-table .mat-row-editing.mat-row-inactive {
  height: 0;
  min-height: 0;
  padding: 0 !important;
  border-bottom: 0 !important;
}

/*added on 11/10/18*/
.mat-raised-button.mat-primary, .btn-accent {
  background-color: #789d6f !important;
}

.mat-raised-button.mat-primary:hover, .btn-accent:hover {
  background-color: #4b7541;
}

.btn-accent {
  background-color: #789d6f !important;
  border-color: #789d6f !important;
}

.btn-accent:hover {
  background-color: #4b7541 !important;
  border-color: #4b7541 !important;
}

.btn.btn-accent {
  color: #fff !important;
}

.m-portlet__body .btn-accent {
  box-shadow: none !important;
}

.m-login__head .m--font-danger {
  color: #5d7553 !important;
}

.mat-button {
  background: rgb(226, 220, 220) !important;
}

.m-header .m-header__top, .m-brand {
  background: #fff !important;
}

.m-topbar__nav .mat-stroked-button {
  border-color: #789d6f !important;
  color: #789d6f !important;
  margin-left: 20px !important;
}

.ant-pagination-item-active {
  border-color: #789d6f;
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: #789d6f;
}

body {
  background-color: #F2F3F8 !important;
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th {
  padding: 10px 16px !important;
}

.ant-popover-message > .anticon {
  color: #789d6f;
}

.ant-btn-primary {
  background-color: #789d6f;
  border-color: #789d6f;
}

.ant-btn-primary:hover {
  background-color: #789d6f;
  border-color: #789d6f;
}

.ant-btn:focus, .ant-btn:hover {
  color: black;
  border-color: #789d6f;
}

.ant-table, .ant-tabs {
  font-family: inherit !important;
}

.ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-tbody > tr:hover > td, .ant-table-thead > tr.ant-table-row-hover > td, .ant-table-thead > tr:hover > td {
  background: #d7f5cf;
}

.ant-switch-checked {
  background-color: #789d6f !important;
}

.ant-select-selection:hover, .ant-select-selection:focus {
  border-color: #789d6f !important;
}

.ant-radio-checked:after {
  color: #789d6f !important;
}

.m-subheader__title {
  margin-bottom: 20px;
}

.ant-table-thead > tr > th {
  background: #ebedf2;
}

.ant-table {
  color: #000 !important;
}

.userstab th {
  background-color: #ebedf2;
}

.minht300 {
  min-height: 350px;
}

.m-portlet .m-portlet__body, .m-footer .m-footer__copyright {
  color: #000000 !important;
}

.m-topbar .m-topbar__nav.m-nav > .m-nav__item.m-topbar__user-profile.m-topbar__user-profile--img.m-dropdown--arrow .m-dropdown__arrow {
  color: #fff !important;
}

.m-dropdown__arrow .m-dropdown__arrow--right .m-dropdown__arrow--adjust {
  color: #fff !important;
}

.m-dropdown.m-dropdown--header-bg-fill .m-dropdown__header {
  background-color: #789D6F !important;
  background-image: none !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #789d6f !important;
}

.btn-accent:disabled {
  background-color: #789d6f !important;
  border-color: #789d6f !important;
}

div#dropZone {
  border-color: #e9582a !important;
}

div#dropZone .content {
  color: #000 !important;
}

.mandatory {
  color: #f50000 !important;
  font-weight: 500;
  font-size: 11px;
}

.save {
  color: rgb(32, 145, 4) !important;
}

.iconspace {
  margin-right: 10%;
}

.cancel {
  color: #ff0000 !important;
}

.btnsignin {
  margin-left: 80%;
}

.tblrightalign {
  text-align: right !important;
}

.tblcenteralign {
  text-align: center !important;
}

.tblhide {
  display: none;
}

.action-width {
  width: 15%;
}

.btnspace {
  margin-left: 5%;
}

.btnuploadspace {
  margin-left: 2%;
  margin-right: 2%;
}

.txtmargin {
  margin-top: 4%;
}

.btnchoosefile {
  margin-left: 3%;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

[nz-input] {
  width: 150px;
  margin-right: 8px;
}

.highlight {
  color: #f50;
}

.m-login.m-login--6 .m-login__aside .m-grid__item .m-grid__item .m-login__title {
  line-height: 3;
}

.mt25 {
  padding-top: 25px;
}

@media (min-width: 1601px) {
  .m-container.m-container--responsive.m-container--xxl {
    width: 1650px !important;
  }
}
@media (min-width: 1025px) {
  .card-img-top { /*width: 255px !important; margin: 20px; height: 100px;*/
    max-width: 100%;
  }
  .m-header .m-header__bottom {
    background: #5f9ea0 !important;
  }
  .m-header-menu.m-header-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link .m-menu__link-text {
    color: #fff !important;
  }
  .m-header-menu.m-header-menu--submenu-skin-light .m-menu__nav > .m-menu__item .m-menu__submenu > .m-menu__subnav > .m-menu__item:hover > .m-menu__link .m-menu__link-text {
    color: #676c7b !important;
  }
  .m-aside-left--enabled .m-body .m-wrapper {
    padding-left: 0px !important;
  }
  .m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-footer {
    background: #fff !important;
  }
  .m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-footer .m-footer__wrapper {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .m-header-menu.m-header-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link .m-menu__link-text.activenav {
    color: #fff !important;
  }
  .m-header-menu.m-header-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link .m-menu__hor-arrow {
    color: #fff !important;
  }
  .activenav:before {
    background-color: #bebebe;
  }
  .m-header-menu.m-header-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link.activenav .m-menu__link-text {
    color: #000 !important;
  }
}
@media (max-width: 7685px) {
  .table-overflow {
    overflow-x: scroll;
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
  .m-brand, .m-stack.m-stack--general.m-stack--inline {
    width: 100% !important;
  }
  .m--visible-tablet-and-mobile-inline-block {
    display: inline-block !important;
  }
  .m-header .m-header__top {
    background: transparent !important;
  }
  .hidsmobs {
    display: none !important;
  }
  .btnsignin {
    margin-left: 0% !important;
  }
}
/*added on 11/10/18*/
.m-accordion.m-accordion--bordered .m-accordion__item .m-accordion__item-head, .m-accordion.m-accordion--bordered .m-accordion__item .m-accordion__item-head:hover, .m-accordion.m-accordion--default .m-accordion__item .m-accordion__item-head, .m-accordion.m-accordion--default .m-accordion__item .m-accordion__item-head:hover {
  background: #f2f2f2 !important;
}

.plantable th {
  background: #ebedf2;
}

.m-login__wrapper {
  background: #fff;
}

.m-login__wrapper .btn-accent.m-btn--air, .m-login__wrapper .btn-accent.m-btn--air:hover, .m-login__wrapper .btn.btn-accent:focus {
  box-shadow: none !important;
}

.m-portlet__nav-item .btn-accent.m-btn--air, .m-portlet__nav-item .btn-accent.m-btn--air:hover, .form-group .btn-accent.m-btn--air, .form-group .btn-accent.m-btn--air:hover, .form-group .btn-accent.m-btn--air:focus {
  box-shadow: none !important;
}

.uplpdf .btn-accent.m-btn--air, .uplpdf .btn-accent.m-btn--air:hover, .uplpdf .btn-accent.m-btn--air:focus {
  box-shadow: none !important;
}

.m-portlet__head-tools .btn-accent.m-btn--air:focus {
  box-shadow: none !important;
}

.ant-table-thead > tr > th .ant-table-filter-icon, .ant-table-thead > tr > th .anticon-filter {
  font-size: 16px;
  cursor: pointer;
  top: 5px;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.search-box {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.search-box input {
  width: 188px !important;
  margin-bottom: 8px;
  display: block;
}

.search-box button {
  width: 90px;
}

.search-button {
  margin-right: 8px;
}

[nz-input] {
  width: 130px;
  margin-right: 8px;
}

.crossfade > figure {
  animation: imageAnimation 30s linear infinite 0s;
  backface-visibility: hidden;
  background-size: cover;
  background-position: center center;
  color: transparent;
  height: 100vh;
  left: 0px;
  opacity: 0;
  position: fixed;
  top: 0px;
  width: 50%;
  z-index: 0;
}

.crossfade > figure:nth-child(1) {
  background-image: url("assets/app/media/img/bg/banner-1.png");
  background-color: #00a6b2;
  background-size: auto;
  background-repeat: no-repeat;
}

.crossfade > figure:nth-child(2) {
  animation-delay: 6s;
  background-image: url("assets/app/media/img/bg/banner-2.png");
  background-color: #f65d58;
  background-size: auto;
  background-repeat: no-repeat;
}

.crossfade > figure:nth-child(3) {
  animation-delay: 12s;
  background-image: url("assets/app/media/img/bg/banner-3.png");
  background-color: #722da2;
  background-size: auto;
  background-repeat: no-repeat;
}

.crossfade > figure:nth-child(4) {
  animation-delay: 18s;
  background-image: url("assets/app/media/img/bg/banner-2.png");
  background-color: #f65d58;
  background-size: auto;
  background-repeat: no-repeat;
}

.crossfade > figure:nth-child(5) {
  animation-delay: 24s;
  background-image: url("assets/app/media/img/bg/banner-3.png");
  background-color: #722da2;
  background-size: auto;
  background-repeat: no-repeat;
}

@media (min-width: 1025px) {
  .m-login.m-login--6 .m-login__aside {
    width: 50% !important;
  }
}
@keyframes imageAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }
  8% {
    animation-timing-function: ease-out;
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__heading .m-menu__link-icon, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link .m-menu__link-icon {
  color: #ffffff !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link .m-menu__link-text {
  color: #ffffff !important;
}

.m-aside-left.m-aside-left--skin-dark {
  background-color: #789D6F !important;
  /* background: linear-gradient(to bottom, #65825e 0%,#69a35b 50%,#a8e19b 100%)!important;*/
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__heading .m-menu__ver-arrow, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item > .m-menu__link .m-menu__ver-arrow {
  color: #ffffff !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover {
  background-color: #6a8264 !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item.m-menu__item--active {
  background: rgba(255, 255, 255, 0.2);
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item.m-menu__item--open {
  background-color: #7b9c73 !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--open > .m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--open > .m-menu__link .m-menu__link-text {
  color: #ffffff !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--open > .m-menu__heading .m-menu__ver-arrow, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--open > .m-menu__link .m-menu__ver-arrow {
  color: #ffffff !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item > .m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item > .m-menu__link .m-menu__link-text {
  color: #ffffff !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item > .m-menu__heading .m-menu__ver-arrow, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item > .m-menu__link .m-menu__ver-arrow {
  color: #ffffff !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active {
  background: rgba(156, 179, 165, 0.5) !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover {
  background: rgba(156, 179, 165, 0.5) !important;
}

.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item > .m-menu__heading .m-menu__link-bullet.m-menu__link-bullet--dot > span, .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item > .m-menu__link .m-menu__link-bullet.m-menu__link-bullet--dot > span {
  background-color: #ffffff !important;
}

@media (min-width: 1025px) {
  .m-brand--minimize .m-brand {
    padding-left: 10px !important;
  }
  .m-brand .m-brand__logo {
    padding-right: 20px;
  }
  .m-nav .m-nav__item > .m-nav__link {
    padding: 9px 20px !important;
  }
  .m-dropdown .m-dropdown__wrapper .m-dropdown__body {
    padding: 20px 0 !important;
  }
}
.form-control.focus, .form-control:focus {
  border-color: #789d6f !important;
}

.btn-outline-accent.m-btn--air, .btn-accent.m-btn--air, .m-btn--gradient-from-accent.m-btn--air {
  box-shadow: 0px 5px 10px 2px rgb(120, 157, 111) !important;
}

a, a:hover, a:focus {
  color: #789d6f !important;
}

.m-dropdown__wrapper .m-nav .m-nav__item > .m-nav__link:hover {
  background: rgba(120, 157, 111, 0.2);
}

.ant-table-column-sorter {
  margin-left: 2px;
}

.nowrap {
  white-space: nowrap;
}

nz-dropdown {
  vertical-align: super;
}

.ant-table-thead > tr > th .anticon-filter {
  top: 7px;
}

.m-dropdown__content .m-nav__item--active .m-nav__link--active {
  background: rgba(120, 157, 111, 0.2);
}

.nowrapbtn {
  white-space: normal;
  height: auto;
  min-height: 18px;
  text-align: left;
}

.black-overlay {
  position: fixed !important;
}

.m-login.m-login--6 .m-login__wrapper .m-login__head {
  top: 15px;
}

.sides {
  position: absolute;
  right: 16px;
  margin-top: -46px;
  font-size: 12px;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: hidden;
  margin-bottom: 0px !important;
}

.sectbord {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  padding: 10px 0;
  margin: 10px 0;
}

.ant-table-bordered .ant-table-tbody > tr > td, .ant-table-bordered .ant-table-thead > tr > th {
  word-break: keep-all;
}

/*.m-body .m-content {
    padding: 15px 15px;
}*/
/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.graphtitle {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.downloadbutton {
  font-size: 20px;
  color: #4c7542;
}

.another-file-over-class {
  border: dotted 3px green;
  width: 132px;
  text-align: center;
  height: 57px;
}

.btn-margin {
  margin-right: 10px;
}

.btn-transparent {
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  color: #4b7541;
}

.btn-transparent i {
  font-size: 1.5em;
}

.maxht {
  max-height: 375px;
  overflow: scroll;
}

li.ant-dropdown-menu-item {
  white-space: normal;
}

.cdk-overlay-pane {
  margin-right: 10px;
}

.space-title {
  margin-right: 7px;
}

.tbl-active-row {
  background-color: #adf99a;
}

.drpsize {
  width: 100%;
}

.minhts .card-body {
  min-height: 135px;
}

.padding {
  padding-right: 1% !important;
  padding-left: 1% !important;
  min-width: 19% !important;
}

.ToBeConsidered {
  font-weight: bold;
}

.innerdiv > div {
  display: inline-block;
}

.innerdiv > div input {
  display: inline-block;
  width: 75px;
  margin: 0 10px;
}

.innerdiv > span {
  margin-right: 20px;
}

.custscroll .ant-select-selection.ant-select-selection--multiple {
  max-height: 140px;
  overflow-y: scroll;
}

.flexrow .col-sm-2 {
  padding-left: 5px;
  padding-right: 5px;
}

.flexrow .col-sm-2 .card {
  min-height: 120px;
}

.selplangroup {
  background-color: #d7f5cf;
}

.col-form-label {
  font-weight: 600 !important;
  color: #333 !important;
}

.custscroll span.ant-select-arrow {
  display: none;
}

.m-form .m-form__section.m-form__section--label-align-right .m-form__group > label, .m-form.m-form--label-align-right .m-form__group > label {
  text-align: left !important;
  font-weight: 600;
  color: #333;
}

.col-form-label:after {
  content: ":";
}

.ant-select-search__field__placeholder, .ant-select-selection__placeholder {
  color: #575962;
}

.ant-select {
  font-family: sans-serif, Arial;
  font-size: 13px;
  color: #575962;
}

.ant-radio-wrapper, .ant-dropdown {
  font-family: sans-serif, Arial !important;
  color: #575962;
}

.ant-select-arrow {
  color: #575962;
  font-weight: 700;
}

.ant-select-selection {
  border-color: #ebedf2 !important;
}

th span {
  text-transform: capitalize;
}

.ant-table-thead > tr > th .ant-table-filter-icon {
  top: 10px;
}

.e-btn, .e-css.e-btn, .e-btn:disabled {
  background-color: #4b7541;
}

.e-btn .e-btn-icon {
  color: #fff;
}

.m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
  font-size: 1.5rem !important;
}

.nocolon:after {
  display: none !important;
}

.nocolon {
  font-weight: normal !important;
}

.popupht {
  max-height: 350px;
  overflow-y: scroll;
}

.ant-table-filter-dropdown {
  max-height: 350px;
  overflow-y: scroll;
}

.dispinline {
  display: inline;
}

.space {
  margin-left: 10px;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .m-portlet__head {
  background-color: #789D6F !important;
  border-color: #789D6F !important;
  height: 3.5rem;
  font-size: 1rem !important;
  /*background: linear-gradient(to right, #65825e 0%,#69a35b 50%,#a8e19b 100%)!important;*/
}

.cont {
  border: 1px solid #f3f3f3;
  padding: 5px;
}

.heads {
  background: #f7f7f7;
}

.btn-group-sm > .btn.m-btn--custom, .btn.m-btn--custom.btn-sm {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  margin: 0.1rem;
}

.paddingTop {
  padding-top: 0px !important;
}

.rightbtn .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  right: 9px;
  left: auto;
  background: #789D6F;
  border-radius: 50%;
  padding: 6px 5px;
  line-height: 10px;
  color: #fff;
  top: 12px;
  font-weight: bold;
}

.rightbtn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 4px 40px 0 12px;
}

.m-portlet.billing .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
  font-size: 1.3rem !important;
}

/*::-webkit-calendar-picker-indicator {
    opacity: 0;
	cursor: pointer;
}*/
@-moz-document url-prefix() {
  .dates::after {
    content: "\f073";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 34px;
    font-size: 18px;
    top: 6px;
  }
}
img.altlogo {
  display: none;
}

.m-aside-left--minimize .fulllogo {
  display: none;
}

/*.m-brand--minimize.m-aside-left--minimize img.altlogo {
    display: block;
	width: 40px;
     margin-top: 15px;
     margin-left: -5px;
}*/
.m-brand--minimize.m-aside-left--minimize .m-brand .m-brand__logo {
  display: block !important;
}

.m-brand--minimize.m-aside-left--minimize .m-stack__item.m-stack__item--middle.m-brand__tools {
  padding-left: 20px;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg {
  background: transparent !important;
  border: 1px solid #789D6F !important;
  border-radius: 5px;
  box-shadow: none;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .m-portlet__body {
  background: transparent !important;
}

.fieldset-portlet {
  border: 1px solid #789D6F;
  box-shadow: none !important;
  border-radius: 5px;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .fieldset-portlet .m-portlet__head.custom-bkng {
  background: none !important;
  border: none;
  padding: 0px;
  height: auto !important;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .fieldset-portlet .m-portlet__head.custom-bkng .m-portlet__head-caption {
  background: #d8dcd7;
  padding: 7px 20px 7px 20px;
  margin-top: -20px;
  margin-left: 40px;
  font-style: italic;
  position: relative;
  z-index: 1111;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .fieldset-portlet .m-portlet__head.custom-bkng .m-portlet__head-caption h3.m-portlet__head-text {
  font-size: 1.2rem !important;
}

.fieldset-portlet .m-portlet__body {
  padding: 0.5rem 1rem !important;
}

.fieldset-portlet .m-portlet__head-title label.col-form-label.nocolon.ant-checkbox-wrapper {
  padding: 0px 10px 0 0;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .fieldset-portlet .m-portlet__head.custom-bkng .m-portlet__head-caption:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -5%;
  top: 0;
  background-color: #d8dcd7;
  transform: skewX(-10deg);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #959a94;
}

.m-portlet.m-portlet--success.m-portlet--head-solid-bg .fieldset-portlet .m-portlet__head.custom-bkng .m-portlet__head-caption:after {
  content: "";
  position: absolute;
  transform: skewX(-10deg);
  width: 100%;
  height: 100%;
  right: -10%;
  top: 0;
  background-color: #d8dcd7;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #959a94;
  border-left: none;
}

.fieldset-portlet h3, .fieldset-portlet .m-portlet__head-title label.col-form-label.nocolon.ant-checkbox-wrapper {
  z-index: 111;
}

.m-aside-left, .m-brand {
  width: 235px;
}

@media (min-width: 1025px) {
  .m-aside-left--fixed .m-body {
    padding-left: 235px;
  }
}
.m-body .m-content {
  padding: 20px 15px !important;
}

.m-aside-menu .m-menu__nav > .m-menu__item > .m-menu__submenu .m-menu__subnav > .m-menu__item > .m-menu__link {
  height: auto;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.accordion .ant-table-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 23px;
  height: 23px;
  text-align: center;
  line-height: 22px;
  border: 1px solid #789d6f;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #789d6f;
  font-size: 13px;
  border-radius: 50px;
  color: #fff;
  padding: 0px 2px 0 5px;
  font-weight: bold;
}

.accordion .ant-table-row-collapsed:after {
  content: "\e61f";
  font-family: anticon !important;
}

.accordion .ant-table-row-expanded {
  transform: rotate(90deg);
}

.accordion .ant-table-row-expanded:after {
  content: "\e61f";
  font-family: anticon !important;
}

.accordion .ant-table-thead > tr > th .anticon-filter {
  top: 5px;
}

.accordion .ant-table-bordered .ant-table-thead > tr > th {
  vertical-align: bottom;
  text-align: center;
}

.nowordbreak {
  word-break: keep-all !important;
}

.control-section .dual-list .btn.btn-primary {
  background-color: #789d6f !important;
  border-color: #789d6f !important;
}

.control-section .dual-list .btn.btn-default {
  background-color: #e2e2e2 !important;
  border-color: #e2e2e2 !important;
}

div.record-picker::-webkit-scrollbar-thumb {
  background: #4b7541 !important;
}

.record-picker li.selected {
  background-color: #d7f5cf !important;
}

.icontrash {
  padding-left: 10px;
  color: orangered;
}

/* Changes after Angular Migration */
.ant-table-thead th.ant-table-column-has-sorters:hover, .ant-table-thead th.ant-table-column-sort {
  background: #ebedf2;
}

.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #ebedf2;
}

.ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #ebedf2;
}

.ant-table-column-sorters {
  padding: 0 6px 0 0;
}

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0.3em 0 0;
}

.ant-table-filter-trigger {
  color: #8d8c8c;
  font-size: 15px;
}

.ant-table-filter-dropdown {
  max-height: inherit;
  overflow-y: auto;
}

.ant-table-row-expand-icon::before {
  transform: none;
  content: ">";
  font-family: cursive;
  position: relative;
  background: transparent;
  top: auto;
  left: auto;
  right: auto;
}

.ant-table-row-expand-icon-collapsed::after, .ant-table-row-expand-icon::after {
  display: none;
}

.accordion .ant-table-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 23px;
  height: 23px;
  text-align: center;
  line-height: 13px;
  border: 1px solid #789d6f;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: #789d6f;
  font-size: 19px;
  border-radius: 50px;
  color: #fff;
  padding: 0px 0px 0 1px;
  font-weight: 400;
}

button.ant-table-row-expand-icon.ng-star-inserted.ant-table-row-expand-icon-expanded {
  transform: rotate(90deg);
}

.rightbtn .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 5px;
  left: auto;
  background: #789d6f;
  padding: 6px 5px;
  border-radius: 50%;
  line-height: 10px;
  color: #fff;
  top: 12px;
}

.accordion .ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .anticon-filter {
  top: auto;
}

.ant-table-filter-trigger-container {
  align-items: center;
}

.accordion .ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .anticon-filter {
  color: #bfbfbf;
}

.tbl-active-row {
  background-color: #d7f5cf;
}

.lapbtn .nowrapbtn {
  max-width: 200px;
}

.custwidth .ant-table-fixed col.ng-star-inserted:nth-child(3) {
  width: 12% !important;
  min-width: 12% !important;
}

.custwidths .ant-table-fixed col.ng-star-inserted:nth-child(2) {
  width: 12% !important;
  min-width: 12% !important;
}

.ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: transparent;
}

.accordion .ant-table-thead > tr > th .ant-table-filter-trigger.active .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-trigger.active .anticon-filter {
  color: #1890ff;
}

span.help-icon {
  background-color: #789d6f;
  border-radius: 50px;
  padding: 2px 7px;
  color: #fff;
  font-size: 17px;
  vertical-align: middle;
}

.help-icon svg {
  width: 16px;
  height: 16px;
  margin-top: -5px;
}

.help-icon svg g {
  fill: #fff;
}

.m-brand__logo-wrapper img {
  max-width: 125px;
  max-height: 50px;
}

.ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-tbody > tr:hover > td, .ant-table-thead > tr.ant-table-row-hover > td, .ant-table-thead > tr:hover > td {
  background: #d7f5cf !important;
}

/* Changes after Angular Migration */
.colorcode {
  height: 2.3rem;
  width: 6rem;
}

.ant-popover-inner-content table {
  padding: 10px;
}

.ant-popover-inner-content table td {
  padding: 7px;
  border-bottom: 1px solid #e8e8e8;
}

.ant-popover-inner-content table tr:hover td {
  background-color: #d7f5cf;
}

.ant-popover-inner-content table td nz-tag {
  border: 1px solid #789d6f;
  margin: 3px;
  padding: 2px;
  border-radius: 3px;
  line-height: 30px;
  background: #d7f5cf;
  font-size: 90%;
}

.custscroll {
  max-height: 100px;
  overflow-y: auto;
}

.scrollable_popup {
  max-height: 100vh;
  overflow-y: auto;
}

.ant-table-column-sorter {
  z-index: 111;
}

.plan-consider-yes-tag {
  border-color: #b7eb8f;
  color: #52c41a;
  float: right;
  margin-right: -10px;
  margin-top: 3px;
}

.plan-consider-no-tag {
  border-color: #eb8f8f;
  color: #c41a1a;
  float: right;
  margin-right: -10px;
  margin-top: 3px;
}

.card-border {
  border-color: #789d6f;
}

.card-bottom-border {
  border-bottom: 1px dashed #789d6f;
  padding-bottom: 7px;
}

.card-top-border {
  border-top: 1px dashed #789d6f;
  padding-top: 7px;
}

h5.card-bottom-border {
  font-size: 13px;
}

.card-body .badge {
  position: absolute;
  right: -11px;
  top: -12px;
  padding: 5px;
  font-size: 13px;
  min-width: 32px;
}

.automation .ant-modal-footer .ant-btn:first-child {
  display: none;
}

.ant-modal.automation {
  width: 75% !important;
}

.min100 .card {
  height: 110px;
  justify-content: center;
  /* align-items: center;
  align-content: center; */
  flex-direction: row;
}

.minht225 {
  min-height: 110px;
}

.minht225 .card-body {
  padding-top: 10px;
  padding-bottom: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.boxactive {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transform: scale(1);
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

.absposbage {
  position: absolute;
  top: 10px;
}

.btn-accent.m-btn--air:hover, .btn-accent.m-btn--air:focus, .btn-accent.m-btn--air:active {
  box-shadow: 0px 5px 10px 2px rgba(126, 155, 119, 0.7) !important;
}

.fivegicon img {
  vertical-align: text-bottom;
  margin-left: 5px;
}

.inlinesspan span {
  display: inline;
}

.custabs .nav-link {
  background-color: #d7f5cf;
  padding: 15px;
  font-size: 14px;
  color: #000 !important;
  border-radius: 20px 20px 0 0 !important;
}

.custabs .nav-link.active {
  background-color: #789D6F !important;
  color: #fff !important;
  z-index: 1111;
  position: relative;
}

.custabs {
  margin-bottom: 0px !important;
}

.custabs.nav.nav-tabs .nav-item {
  margin-left: -10px;
}

.tab-content {
  padding: 15px 15px;
  border: 1px solid #e8e8e8;
  margin-top: -1px;
}

.primary-bgcolor {
  background-color: #789D6F;
}